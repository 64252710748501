import "./App.css";
import SupportPage from "./pages/SupportPage";

function App() {
  return (
    <div className="App">
      <SupportPage />
    </div>
  );
}

export default App;
