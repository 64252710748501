import React from "react";
import "../styles/supportpage.scss";

export default function SupportPage() {
  return (
    <div className="container">
      <div className="top-section">
        <h3 className="welcome-text">Welcome Micah! How can we help?</h3>
        <div className="search-container">
          <input type="text" className="search-input" placeholder="Search Something"/>
          <button className="search-button">Search</button>
        </div>
      </div>
    </div>
  );
}
